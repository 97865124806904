import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { Dropdown } from 'src/components/Dropdown/Dropdown'
import { DropdownItem } from 'src/components/Dropdown/DropdownItem'
import { RequestHandler, SuccessHandler, useApiRequest } from 'src/hooks/useApiRequest'
import { orgUnitsSelectors } from 'src/store/adapters/orgUnits'
import { replaceAllOrgUnits } from 'src/store/reducers/orgUnits'
import { setActiveOrgUnit, setOperationActiveVehicleId } from 'src/store/reducers/settings'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { OrgUnit } from 'src/types/OrgUnit'
import { Select } from 'src/types/Select'

import { Divider, styled, Typography } from '@mui/material'

export type OrganisationDropdownFragmentProps = {
  //
}

export const OrganisationDropdownFragment: React.FC<OrganisationDropdownFragmentProps> = () => {
  const activeOrgUnit = useAppSelector(state => state.settings.activeOrgUnit)
  const orgUnits = useAppSelector(state => orgUnitsSelectors.selectAll(state))
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { complete, request } = useApiRequest()

  // Retrieve Org Units Via Multi Select
  useEffect(() => {
    const handler: RequestHandler<Select> = () => {
      return CarshareApiService.get<Select>('getSelects', {
        kinds: 'select-org-unit-id:org_units_authorised',
      })
    }
    const onSuccess: SuccessHandler<Select> = (response) => {
      const attributes = response.find(result => result.name === 'select-org-unit-id:org_units_authorised')?.tuples || []
      const multiselectOrgUnits: OrgUnit[] = attributes.map(attribute => {
        return {
          ORG_UNIT_ID: attribute.name,
          org_unit_name: attribute.text,
        } as OrgUnit
      })

      // add a default org unit to multiselectOrgUnits
      multiselectOrgUnits.unshift({
        ORG_UNIT_ID: '0',
        org_unit_name: 'Show All',
      } as OrgUnit)

      if (!activeOrgUnit) {
        dispatch(setActiveOrgUnit(multiselectOrgUnits[0]))
      }
      

      dispatch(replaceAllOrgUnits(multiselectOrgUnits))
    }

    request(handler, onSuccess)
  }, [])

  // Handle Org Unit Change
  const handleChange = (value: string) => {
    if (activeOrgUnit?.ORG_UNIT_ID === value) return
    
    const updatedActiveOrgUnit = orgUnits.find(orgUnit => orgUnit.ORG_UNIT_ID === value)

    dispatch(setOperationActiveVehicleId(null))
    dispatch(setActiveOrgUnit(updatedActiveOrgUnit))
  }

  return ((complete || orgUnits.length > 0) && !location.pathname.includes('settings') && !location.pathname.includes('reports')) ? (
    <StyledDropdown
      value={activeOrgUnit?.org_unit_name || ''}
      onSelectItem={handleChange}
    >
      <div>
        <DropdownItem key={orgUnits?.[0]?.ORG_UNIT_ID} data-value={orgUnits?.[0]?.ORG_UNIT_ID}>{orgUnits?.[0]?.org_unit_name}</DropdownItem>
        <Divider sx={{ marginTop: 0, marginBottom: 0 }}/>
        <StyledDropdownTypography variant="body1">Organisation Units:</StyledDropdownTypography>
      </div>
      {orgUnits.slice(1).map((orgUnit) => {
        return (
          <DropdownItem key={orgUnit.ORG_UNIT_ID} data-value={orgUnit.ORG_UNIT_ID}>{orgUnit.org_unit_name}</DropdownItem>
        )
      })}
    </StyledDropdown>
  ) : null
}

const StyledDropdown = styled(Dropdown)`
  width: ${props => props.theme.typography.pxToRem(180)};

  ${(props) => props.theme.breakpoints.up('sm')} {
    width: ${props => props.theme.typography.pxToRem(260)};
  }
`

const StyledDropdownTypography = styled(Typography)`
  color: ${props => props.theme.palette.text.primary};
  font-size: ${props => props.theme.typography.pxToRem(14)};
  font-weight: 500;
  margin-left: ${props => props.theme.typography.pxToRem(16)};
  margin-bottom: ${props => props.theme.typography.pxToRem(8)};
`